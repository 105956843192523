import { Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import AchievementTwo from "../component/section/achievement-2";
import Blog from "../component/section/blog";
import Instructor from "../component/section/instructor";
import Skill from "../component/section/skill";
import Student from "../component/section/student";
import Image1 from "../assets/images/about/01.jpg";
import Image2 from "../assets/images/about/01.png";

const subTitle = "About Our Alright Academy";
const title = "Good Qualification Services And Better Skills";
const desc =
  "Distinctively provide acces mutfuncto users whereas transparent proceses somes ncentivize eficient functionalities rather than extensible archtectur communicate leveraged services and cross-platform.";

const year = "30+";
const expareance = "Years Of Experiences";

const aboutList = [
  {
    imgUrl: "assets/images/about/icon/01.jpg",
    imgAlt: "about icon rajibraj91 rajibraj",
    title: "Skilled Instructors",
    desc: "Distinctively provide acces mutfuncto users whereas communicate leveraged services",
  },
  {
    imgUrl: "assets/images/about/icon/02.jpg",
    imgAlt: "about icon rajibraj91 rajibraj",
    title: "Get Certificate",
    desc: "Distinctively provide acces mutfuncto users whereas communicate leveraged services",
  },
  {
    imgUrl: "assets/images/about/icon/03.jpg",
    imgAlt: "about icon rajibraj91 rajibraj",
    title: "Online Classes",
    desc: "Distinctively provide acces mutfuncto users whereas communicate leveraged services",
  },
];

const AboutPage = () => {
  return (
    <Fragment>
      <Header />
      <PageHeader title={"About Our Alright Academy"} curPage={""} />
      <div className="about-section style-3 padding-tb section-bg">
        <div className="container">
          <div className="row row-cols-1">
            <div className="col px-12">
              <div className="about-right">
                {/* <div className="section-header">
                  <span className="subtitle text-center">{subTitle}</span>
                  <h2 className="subtitle text-center">{title}</h2>
                </div> */}

                <div className="section-header">
                  <h2
                    className="subtitle text-center mb-5"
                    style={{ fontSize: "2rem", letterSpacing: "0px" }}
                  >
                    Welcome to Alright Academy, located in the heart of
                    Pokhara's busy New Road area.
                  </h2>

                  <div className="container">
                    <div className="row" style={{ marginBottom: "8rem" }}>
                      <div className="col-12 col-md">
                        <h2 className="title ">
                          Shaping Your Future Success - Alright Academy, Pokhara
                        </h2>
                        <p>
                          At Alright Academy, we think that achieving one's
                          goals is important. At first Coffee Ghar offered
                          Barista courses and training sessions. Then after its
                          successful release and popularity, we introduced
                          alright academy with other variety of courses in
                          hospitality and management like barista, bakery
                          courses, bartending courses and sushi courses.
                        </p>{" "}
                        <br />
                        <p style={{ fontStyle: "italic", fontWeight: "600" }}>
                          "Our mission is to provide every training and course
                          related to hospitality services and management."
                        </p>{" "}
                        <br />
                        <p>
                          Our academy, located in the heart of Pokhara, is
                          dedicated to providing students with hands-on
                          experience and industry insights. To fully succeed in
                          the changing hospitality industry, we believe that
                          academic knowledge must be supported by hands-on
                          training. Our focus is not just on skill development,
                          but also on career development.
                        </p>
                      </div>
                      <div className="col-12 col-md about-img d-sm-md">
                        <img src={Image1} alt="img1" width={"100%"} />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12 col-md about-img">
                        <img src={Image2} alt="img2" width={"100%"} />
                      </div>
                      <div className="col-12 col-md">
                        {" "}
                        <h2 className="title text-center">
                          From Coffee to Career: Our Pathway to Success
                        </h2>
                        <p>
                          Alright Academy is more than just a school; it's a
                          pathway to a rewarding career in the hospitality
                          industry. What sets us apart is our dedication to
                          students planning to study abroad.
                        </p>
                        <br />
                        <p>
                          Our "Abroad Focus" courses are designed to provide you
                          with the knowledge, perspective, and adaptability
                          required to thrive on an international stage. We take
                          pleasure in being a trusted choice among Nepali
                          students wishing to pursue overseas jobs.
                        </p>
                        <br />
                        <p>
                          Contact us today to begin your exciting journey with
                          Alright Academy. Whether you want to be a barista,
                          baker, bartender, or sushi maker, our institute is a
                          great place to start.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </Fragment>
  );
};

export default AboutPage;
