import { Link } from "react-router-dom";
import Rating from "../sidebar/rating";

const subTitle = "Loved by 100+ Students";
const title = "Students Feedback On Our Courses";

const studentList = [
  {
    imgUrl: "assets/images/feedback/student/01.jpg",
    imgAlt: "student rajibraj91 rajibraj",
    name: "Suhana Gautam",
    degi: "UX designer",
    desc: "As a coffee lover, the Barista course was a dream come true. Learning about espresso extraction, milk frothing techniques, and coffee tasting has made me confident in pursuing a career in becoming a Barista",
  },
  {
    imgUrl: "assets/images/feedback/student/02.jpg",
    imgAlt: "student rajibraj91 rajibraj",
    name: "Apurva Tamang",
    degi: "UX designer",
    desc: "Alright Academy's Bakery course met my expectations. I started as an amateur baker, and now I'm confidently creating pastries and bread. The course has ignited a passion that I'm eager to turn into a profession in the coming future.",
  },
];

const Student = () => {
  return (
    <div className="student-feedbak-section padding-tb shape-img">
      <div className="container">
        <div className="section-header text-center">
          <span className="subtitle">{subTitle}</span>
          <h2 className="title">{title}</h2>
        </div>
        <div className="section-wrapper" style={{ padding: "2rem" }}>
          <div className="row justify-content-center row-cols-lg-2 row-cols-1 ">
            <div className="col">
              <div className="sf-left">
                <div className="sfl-thumb">
                  <img
                    src="assets/images/feedback/01.jpg"
                    alt="student feedback"
                  />
                  {/* <a
                    href="https://www.youtube.com/embed/MU3qrgR2Kkc"
                    className="video-button popup"
                    target="_blank"
                  >
                    <i className="icofont-ui-play"></i>
                  </a> */}
                </div>
              </div>
            </div>
            <div className="col" style={{ paddingLeft: "8rem" }}>
              {studentList.map((val, i) => (
                <div className="stu-feed-item" key={i}>
                  <div className="stu-feed-inner">
                    <div className="stu-feed-top">
                      <div className="sft-left">
                        <div className="sftl-thumb">
                          <img src={`${val.imgUrl}`} alt={val.imgAlt} />
                        </div>
                        <div className="sftl-content">
                          <Link to="/team-single">
                            <h6>{val.name}</h6>
                          </Link>
                          {/* <span>{val.degi}</span> */}
                        </div>
                      </div>
                      <div className="sft-right">
                        <Rating />
                      </div>
                    </div>
                    <div className="stu-feed-bottom">
                      <p>{val.desc}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Student;
