import { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

const phoneNumber = "+977-980-2802464";
const address = "New Road, Pokhara, Kaski, Nepal";

let socialList = [
  {
    iconName: "icofont-facebook",
    siteLink: "https://www.facebook.com/alrightacademy/",
  },
  {
    iconName: "icofont-instagram",
    siteLink: "https://www.instagram.com/alrightacademy.hm/",
  },
  // {
  //   iconName: "icofont-vimeo",
  //   siteLink: "#",
  // },
  // {
  //   iconName: "icofont-skype",
  //   siteLink: "#",
  // },
  // {
  //   iconName: "icofont-rss-feed",
  //   siteLink: "#",
  // },
];

const Header = () => {
  const [menuToggle, setMenuToggle] = useState(false);
  const [socialToggle, setSocialToggle] = useState(false);
  const [headerFiexd, setHeaderFiexd] = useState(false);
  const location = useLocation();

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 200) {
        setHeaderFiexd(true);
      } else {
        setHeaderFiexd(false);
      }
    });

    let mybutton = document.getElementById("btn-back-to-top");

    // When the user scrolls down 20px from the top of the document, show the button
    window.onscroll = function () {
      scrollFunction();
    };

    function scrollFunction() {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        mybutton.style.display = "block";
      } else {
        mybutton.style.display = "none";
      }
    }
    // When the user clicks on the button, scroll to the top of the document

    mybutton.addEventListener("click", backToTop);

    function backToTop() {
      const currentScrollPosition = window.scrollY;
      const targetScrollPosition = 0;
      const animationDuration = 500; // milliseconds
      // Start the smooth scroll animation.
      window.scrollTo({
        top: targetScrollPosition,
        behavior: "smooth",
        duration: animationDuration,
      });
    }
  }, []);

  const handleClick = () => {
    window.location.href = "https://www.google.com";
  };

  return (
    <header
      className={`header-section ${headerFiexd ? "header-fixed fadeInUp" : ""}`}
    >
      <div className={`header-top ${socialToggle ? "open" : ""}`}>
        <div className="container">
          <div className="header-top-area">
            <ul className="lab-ul left">
              <li>
                <a href="tel:+9779802802464" target="_blank">
                  <i className="icofont-ui-call"></i> <span>{phoneNumber}</span>
                </a>
              </li>
              <li>
                <i className="icofont-location-pin"></i> {address}
              </li>
            </ul>
            <ul className="lab-ul social-icons d-flex align-items-center">
              <li>
                <p>Find us on : </p>
              </li>
              {socialList.map((val, i) => (
                <li key={i}>
                  <a href={val.siteLink} target="_blank">
                    <i className={val.iconName}></i>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="header-bottom">
        <div className="container">
          <div className="header-wrapper">
            <div className="logo">
              <Link
                to="/"
                className={`${
                  location.pathname === "/" ? "noMousePointer" : ""
                }`}
              >
                <img
                  style={{ width: " 140px" }}
                  src="assets/images/text-logo.svg"
                  alt="logo"
                />
              </Link>
            </div>
            <div className="menu-area">
              <div className="menu">
                <ul className={`lab-ul ${menuToggle ? "active" : ""}`}>
                  <li className={location.pathname === "/" ? "activeLink" : ""}>
                    <NavLink to="/">Home</NavLink>
                  </li>
                  <li
                    className={
                      location.pathname === "/course" ? "activeLink" : ""
                    }
                  >
                    <NavLink to="/course">Courses</NavLink>
                  </li>
                  <li
                    className={
                      location.pathname === "/about" ? "activeLink" : ""
                    }
                  >
                    <NavLink to="/about">About</NavLink>
                  </li>
                  {/* <li>
                    <NavLink to="/team">Team</NavLink>
                  </li>
                  <li>
                    <NavLink to="/instructor">Instructor</NavLink>
                  </li> */}

                  {/* <li className="menu-item-has-children">
                    <a
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      data-bs-offset="0,0"
                    >
                      Courses
                    </a>
                    <ul className="lab-ul dropdown-menu">
                      <li>
                        <NavLink to="/course">Course</NavLink>
                      </li>
                      <li>
                        <NavLink to="/course-single">Course Details</NavLink>
                      </li>
                      <li>
                        <NavLink to="/course-view">Course View</NavLink>
                      </li>
                    </ul>
                  </li> */}
                  {/* <li className="menu-item-has-children">
                    <a
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      data-bs-offset="0,0"
                    >
                      Blog
                    </a>
                    <ul className="lab-ul dropdown-menu">
                      <li>
                        <NavLink to="/blog">Blog Grid</NavLink>
                      </li>
                      <li>
                        <NavLink to="/blog-2">Blog Style 2</NavLink>
                      </li>
                      <li>
                        <NavLink to="/blog-3">Blog Style 3</NavLink>
                      </li>
                      <li>
                        <NavLink to="/blog-single">Blog Single</NavLink>
                      </li>
                    </ul>
                  </li> */}
                  {/* <li className="menu-item-has-children">
                    <a
                      href="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      data-bs-offset="0,0"
                    >
                      Pages
                    </a>
                    <ul className="lab-ul dropdown-menu">
                      <li>
                        <NavLink to="/about">About</NavLink>
                      </li>
                      <li>
                        <NavLink to="/team">Team</NavLink>
                      </li>
                      <li>
                        <NavLink to="/instructor">Instructor</NavLink>
                      </li>
                      <li>
                        <NavLink to="/shop">Shop Page</NavLink>
                      </li>
                      <li>
                        <NavLink to="/shop-single">Shop Details Page</NavLink>
                      </li>
                      <li>
                        <NavLink to="/cart-page">Shop Cart Page</NavLink>
                      </li>
                      <li>
                        <NavLink to="/search-page">Search Page</NavLink>
                      </li>
                      <li>
                        <NavLink to="/search-none">Search None</NavLink>
                      </li>
                      <li>
                        <NavLink to="/404">404</NavLink>
                      </li>
                    </ul>
                  </li> */}
                  <li
                    className={
                      location.pathname === "/contact" ? "activeLink" : ""
                    }
                  >
                    <NavLink to="/contact">Contact</NavLink>
                  </li>
                </ul>
              </div>

              {/* <Link to="/login" className="login">
                <i className="icofont-user"></i> <span>LOG IN</span>{" "}
              </Link>
              <Link to="/signup" className="signup">
                <i className="icofont-users"></i> <span>SIGN UP</span>{" "}
              </Link> */}

              <div
                className={`header-bar d-lg-none ${menuToggle ? "active" : ""}`}
                onClick={() => setMenuToggle(!menuToggle)}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div
                className="ellepsis-bar d-lg-none"
                onClick={() => setSocialToggle(!socialToggle)}
              >
                <i className="icofont-info-square"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        type="button"
        class="btn btn-warning btn-floating btn-lg"
        id="btn-back-to-top"
      >
        <i class="icofont-arrow-up" style={{ color: "#02405B" }}></i>
      </button>{" "}
      <a
        type="button"
        class="btn btn-warning btn-floating btn-lg"
        id="whatsApp"
        href="https://wa.me/9779802802464"
        target="_blank"
      >
        <i class="icofont-brand-whatsapp"></i>
      </a>
    </header>
  );
};

export default Header;
