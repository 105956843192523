import { Link } from "react-router-dom";
import Rating from "../sidebar/rating";

const subTitle = "Featured Courses";
const title = "Courses That Match Your Passion";

const courseList = [
  {
    imgUrl: "./assets/images/course/alright/Coffee Couse.jpg",
    imgAlt: "Barista Course",
    price: "$30s",
    cate: "Adobe XD",
    reviewCount: "03 reviews",
    title: "Barista Course",
    totalLeson: "2 Courses",
    schdule: "Basic Course",
    lesson2: "1 Month with internship - 10 days to 3 months volunteer)",
    schdule2: "Abroad Focus Full Barista Course",
    authorImgUrl: "assets/images/course/author/01.jpg",
    authorImgAlt: "course author rajibraj91 rajibraj",
    authorName:
      "Abroad Focus Full Barista Course (1 Month with internship - 10 days to 3 months volunteer",
    btnText: "Read More",
    coursePlanList: [
      "Basic Course (1 Month)",
      "Abroad Focus Full Barista Course (1 Month with internship 10 days to 3 months volunteer)",
    ],
  },

  {
    imgUrl: "./assets/images/course/alright/Sushi Couse.png",
    imgAlt: "Sushi Course Image",
    price: "$30",
    cate: "Adobe XD",
    reviewCount: "03 reviews",
    title: "Sushi Course",
    totalLeson: "2 Course",
    schdule: "Basic Course",
    authorImgUrl: "assets/images/course/author/04.jpg",
    authorImgAlt: "course author rajibraj91 rajibraj",
    authorName: "Abroad Focus Full Sushi Course (1 Month internship)",
    btnText: "Read More",
    coursePlanList: [
      "Basic Course (1 Month)",
      "Abroad Focus Full Sushi Course (1 Month internship)",
    ],
  },
  {
    imgUrl: "./assets/images/course/alright/Bakery Course.png",
    imgAlt: "Bakery Course",
    price: "$30",
    cate: "Adobe XD",
    reviewCount: "03 reviews",
    title: "Bakery Course",
    totalLeson: "3 Course",
    schdule: "Basic Course",
    authorImgUrl: "assets/images/course/author/02.jpg",
    authorImgAlt: "course author rajibraj91 rajibraj",
    authorName: "Umme Nishat",
    btnText: "Read More",
    coursePlanList: [
      "Basic Course (1 Month)",
      "Abroad Focus Full Bakery Course (2 months)",
      "Advanced Bakery Course (3 months with internship 1 to 3 months)",
    ],
  },
  {
    imgUrl: "./assets/images/course/alright/Bardender Course.jpeg",
    imgAlt: "Bartender Course Image",
    price: "$30",
    cate: "Adobe XD",
    reviewCount: "03 reviews",
    title: "Bartender Course",
    totalLeson: "3 Course",
    schdule: "Basic Course",
    authorImgUrl: "assets/images/course/author/03.jpg",
    authorImgAlt: "course author rajibraj91 rajibraj",
    authorName: "Robot Smith",
    btnText: "Read More",
    coursePlanList: [
      "Basic Course (2 months with internship)",
      "Advance Course (with 1 Month to 3 months internship)",
      "Abroad Focus Full Bartender Course (2 months with internship)",
    ],
  },
  //   {
  //     imgUrl: "assets/images/course/11.jpg",
  //     imgAlt: "course rajibraj91 rajibraj",
  //     price: "$30",
  //     cate: "Adobe XD",
  //     reviewCount: "03 reviews",
  //     title: "Boozy Halloween Drinks for the Grown Eleifend Kuismod",
  //     totalLeson: "18x Lesson",
  //     schdule: "Online Class",
  //     authorImgUrl: "assets/images/course/author/05.jpg",
  //     authorImgAlt: "course author rajibraj91 rajibraj",
  //     authorName: "Billy Rivera",
  //     btnText: "Read More",
  //   },
  //   {
  //     imgUrl: "assets/images/course/12.jpg",
  //     imgAlt: "course rajibraj91 rajibraj",
  //     price: "$30",
  //     cate: "Adobe XD",
  //     reviewCount: "03 reviews",
  //     title: "Student Want to Learn About Science And Arts",
  //     totalLeson: "18x Lesson",
  //     schdule: "Online Class",
  //     authorImgUrl: "assets/images/course/author/06.jpg",
  //     authorImgAlt: "course author rajibraj91 rajibraj",
  //     authorName: "Subrina Kabir",
  //     btnText: "Read More",
  //   },
];

const CourseTwo = () => {
  return (
    <div className="course-section padding-tb">
      <div className="container">
        <div className="section-header text-center">
          <span className="subtitle">{subTitle}</span>
          <h2 className="title">{title}</h2>
        </div>
        <div className="section-wrapper">
          <div className="row g-4 justify-content-center row-cols-lg-2 row-cols-1">
            {courseList.map((val, i) => (
              <div className="col" key={i}>
                <div className="course-item style-2">
                  <div className="course-inner" style={{ minHeight: "340px" }}>
                    <div className="course-thumb">
                      <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                      {/* <div className="course-price">{val.price}</div> */}
                    </div>
                    <div className="course-content">
                      <div className="course-category">
                        {/* <div className="course-cate">
                          <a href="#">{val.cate}</a>
                        </div> */}
                      </div>
                      <Link to="/course">
                        <h4>{val.title}</h4>
                      </Link>

                      <div className="course-details">
                        <div className="couse-count">
                          <i className="icofont-library"></i> {val.totalLeson}
                        </div>
                        {/* <div className="couse-topic">
                          <i className="icofont-signal"></i> {val.schdule}
                        </div> */}
                      </div>
                      <div className="course-footer">
                        <div>
                          <ul style={{ listStyleType: "square" }}>
                            {val.coursePlanList.map((plan, i) => (
                              <li key={i}>{plan}</li>
                            ))}
                          </ul>
                        </div>
                        <div className="course-author">
                          {/* <img
                            src={`${val.authorImgUrl}`}
                            alt={`${val.authorImgAlt}`}
                          /> */}
                          {/* <Link to="/team-single" className="ca-name">
                            {val.authorName}
                          </Link> */}
                        </div>
                        <div className="course-btn">
                          <Link to="/course" className="lab-btn-text">
                            {val.btnText}{" "}
                            <i className="icofont-external-link"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseTwo;
