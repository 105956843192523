import { Link } from "react-router-dom";

const subTitle = "UPCOMING COURSES";
const title = "For Your Career Path in Hospitality";

const featureList = [
  {
    imgUrl: "assets/images/feature/01.png",
    imgAlt: "feature rajibraj91 rajibraj",
    title: "Cooking/Culinary Arts",
    titleSpan: "Facelities",
    btnText: "View More",
  },
  {
    imgUrl: "assets/images/feature/02.png",
    imgAlt: "feature rajibraj91 rajibraj",
    title: "House Keeping",
    titleSpan: "Courses",
    btnText: "View More",
  },
  {
    imgUrl: "assets/images/feature/03.png",
    imgAlt: "feature rajibraj91 rajibraj",
    title: "Front Office",
    titleSpan: "Course Complete",
    btnText: "View More",
  },
  {
    imgUrl: "assets/images/feature/04.png",
    imgAlt: "feature rajibraj91 rajibraj",
    title: "Waiter",
    titleSpan: "Ingenious Instructor",
    btnText: "View More",
  },
  {
    imgUrl: "assets/images/feature/05.png",
    imgAlt: "feature rajibraj91 rajibraj",
    title: "Bussiness Management",
    titleSpan: "Room",
    btnText: "View More",
  },
  {
    imgUrl: "assets/images/feature/06.png",
    imgAlt: "feature rajibraj91 rajibraj",
    title: "Home Care/Oldage Care",
    titleSpan: "Leader",
    btnText: "View More",
  },
];

const Feature = () => {
  return (
    <div className="feature-section padding-tb">
      <div className="container">
        <div className="section-header text-center">
          <span className="subtitle">{subTitle}</span>
          <h2 className="title">{title}</h2>
        </div>
        <div className="section-wrapper">
          <div className="row g-4 row-cols-lg-3 row-cols-sm-2 row-cols-1 justify-content-center">
            {featureList.map((val, i) => (
              <div className="col" key={i}>
                <div className="feature-item">
                  <div className="feature-inner">
                    <div className="feature-thumb">
                      <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                    </div>
                    <div className="feature-content">
                      <Link to="/course-single">
                        <h5>
                          {val.title} <span>{val.titleSpan}</span>
                        </h5>
                      </Link>
                      <Link to="/course-single" className="lab-btn-text">
                        {val.btnText} <span></span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feature;
