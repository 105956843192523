const subTitle = "Welcome to Alright Academy";
const title = (
  <h4 className="title">
    <span className="d-lg-block">Crafting Excellence </span>{" "}
    <span className="d-lg-block">in Hospitality Courses</span>
  </h4>
);
const desc =
  "Discover a range of courses and begin your path towards a rewarding career in the hospitality industry. ";
const catagoryList = [
  {
    name: "Barista",
    link: "#",
  },
];

const shapeList = [
  {
    name: "Abroad Focus Courses",
    link: "#",
    className: "ccl-shape shape-1",
  },
  {
    name: "100+ Students",
    link: "#",
    className: "ccl-shape shape-2",
  },
  {
    name: "Exciting Future Courses",
    link: "#",
    className: "ccl-shape shape-3",
  },
  {
    name: "Career In Hospitality",
    link: "#",
    className: "ccl-shape shape-4",
  },
];

const Banner = () => {
  return (
    <section className="banner-section">
      <div className="container">
        <div className="section-wrapper">
          <div className="row align-items-center">
            <div className="col-xxl-5 col-xl-6 col-lg-10">
              <div className="banner-content">
                <h6 className="subtitle text-uppercase fw-medium">
                  {subTitle}
                </h6>
                {title}
                <p className="desc">{desc}</p>
                {/* <form action="/">
                  <div className="banner-icon">
                    <i className="icofont-search"></i>
                  </div>
                  <input type="text" placeholder="Keywords of your course" />
                  <button type="submit">Search Course</button>
                </form>
                <div className="banner-catagory d-flex flex-wrap">
                  <p>Most Popular : </p>
                  <ul className="lab-ul d-flex flex-wrap">
                    {catagoryList.map((val, i) => (
                      <li key={i}>
                        <a href={val.link}>{val.name}</a>
                      </li>
                    ))}
                  </ul>
                </div> */}
              </div>
            </div>
            <div className="col-xxl-7 col-xl-5">
              <div className="banner-thumb">
                <img
                  src="assets/images/banner/01.png"
                  alt="img"
                  width={"85%"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="all-shapes"></div>
      <div className="cbs-content-list d-none">
        <ul className="lab-ul">
          {shapeList.map((val, i) => (
            <li className={val.className} key={i}>
              <a href={val.link}>{val.name}</a>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default Banner;
