import { Link, useLocation } from "react-router-dom";
import darkLogo from "../../assets/images/darkLogo.png";

const newsTitle = "Want Us To Email You About Special Offers And Updates?";
const siteTitle = "Site Map";
const useTitle = "Useful Links";
const socialTitle = "Social Contact";
const supportTitle = "Our Support";

const siteList = [
  {
    text: "Documentation",
    link: "#",
  },
  {
    text: "Feedback",
    link: "#",
  },
  {
    text: "Support Forums",
    link: "#",
  },
];

const useList = [
  {
    text: "About Us",
    link: "/about",
  },
  {
    text: "Courses",
    link: "/course",
  },
  {
    text: "Contact Us",
    link: "/contact",
  },
  {
    text: "Terms & Conditions",
    link: "/privacypolicy",
  },
];

const socialList = [
  {
    text: "Facebook",
    iconName: "icofont-facebook",
    link: "https://www.facebook.com/alrightacademy",
  },

  {
    text: "Instagram",
    iconName: "icofont-instagram",
    link: "https://www.instagram.com/alrightacademy.hm/",
  },
];

const supportList = [
  {
    text: "Help Center",
    link: "#",
  },
  {
    text: "Status",
    link: "#",
  },
  {
    text: "Contact Support",
    link: "#",
  },
];

const Footer = () => {
  const location = useLocation();
  return (
    <div className="news-footer-wrap">
      {/* <div className="fs-shape">
        <img src="assets/images/shape-img/03.png" alt="fst" className="fst-1" />
        <img src="assets/images/shape-img/04.png" alt="fst" className="fst-2" />
      </div> */}
      {/* 
      <div className="news-letter">
        <div className="container">
          <div className="section-wrapper">
            <div className="news-title">
              <h3>{newsTitle}</h3>
            </div>
            <div className="news-form">
              <form action="/">
                <div className="nf-list">
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter Your Email"
                  />
                  <input type="submit" name="submit" value="Subscribe Now" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}

      <footer>
        <div className="footer-top padding-tb pt-5">
          <div className="container">
            <div className="row g-4 row-cols-xl-3 row-cols-md-2 row-cols-1 justify-content-center">
              {/* <div className="col">
                <div className="footer-item">
                  <div className="footer-inner">
                    <div className="footer-content">
                      <div className="title">
                        <h4>{siteTitle}</h4>
                      </div>
                      <div className="content">
                        <ul className="lab-ul">
                          {siteList.map((val, i) => (
                            <li key={i}>
                              <a href={val.link}>{val.text}</a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="col d-flex align-items-center">
                <div className="footer-item">
                  <div className="footer-inner">
                    <div className="footer-content">
                      {/* <div className="title">
                        <h4>Alright Academy</h4>
                      </div> */}
                      <div className="logo">
                        <Link
                          to="/"
                          className={`${
                            location.pathname === "/" ? "noMousePointer" : ""
                          }`}
                        >
                          <img
                            style={{ width: " 200px" }}
                            src={darkLogo}
                            alt="logo"
                          />
                        </Link>
                      </div>
                      <p className="text-wrap text-white mt-4">
                        Alright Academy is more than just a school.It's a
                        pathway to a rewarding career in the hospitality
                        industry.
                      </p>
                    </div>
                  </div>
                </div>
              </div>{" "}
              <div className="col">
                <div className="footer-item">
                  <div className="footer-inner">
                    <div className="footer-content">
                      <div className="title">
                        <h4>{useTitle}</h4>
                      </div>
                      <div className="content">
                        <ul
                          className="lab-ul"
                          style={{
                            display: "grid",
                            gridTemplateColumns: "auto auto",
                          }}
                        >
                          {useList.map((val, i) => (
                            <li key={i}>
                              <Link to={val.link}>{val.text}</Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="footer-item">
                  <div className="footer-inner">
                    <div className="footer-content">
                      <div className="title">
                        <h4>{socialTitle}</h4>
                      </div>
                      <div className="content">
                        <ul className="lab-ul d-flex gap-4">
                          {socialList.map((val, i) => (
                            <li key={i}>
                              <a href={val.link} target="_blank">
                                <i className={val.iconName}></i>
                                {val.text}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col">
                <div className="footer-item">
                  <div className="footer-inner">
                    <div className="footer-content">
                      <div className="title">
                        <h4>{supportTitle}</h4>
                      </div>
                      <div className="content">
                        <ul className="lab-ul">
                          {supportList.map((val, i) => (
                            <li key={i}>
                              <a href={val.link}>{val.text}</a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="footer-bottom style-2">
          <div className="container">
            <div className="section-wrapper noMousePointer">
              <p>
                &copy; 2023 <Link to="/">Alright Academy</Link>
              </p>
              {/* <p>
                &copy; 2023 <Link to="/">Alright Academy</Link> Designed by{" "}
                <a
                  href="https://themeforest.net/user/CodexCoder"
                  target="_blank"
                >
                  CodexCoder
                </a>{" "}
              </p> */}
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
