import { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import GroupSelect from "../component/sidebar/group-select";
import Pagination from "../component/sidebar/pagination";
import Rating from "../component/sidebar/rating";
import SkillSelect from "../component/sidebar/skill-select";
import CategoryTwo from "../component/section/category-2";
import Course from "../component/section/course";

const courseList = [
  {
    imgUrl: "assets/images/course/01.jpg",
    imgAlt: "course rajibraj91 rajibraj",
    price: "$30",
    cate: "Adobe XD",
    reviewCount: "03 reviews",
    title: "Fundamentals of Adobe XD Theory Learn New",
    totalLeson: "18x Lesson",
    schdule: "Online Class",
    authorImgUrl: "assets/images/course/author/01.jpg",
    authorImgAlt: "course author rajibraj91 rajibraj",
    authorName: "William Smith",
    btnText: "Read More",
  },
  {
    imgUrl: "assets/images/course/02.jpg",
    imgAlt: "course rajibraj91 rajibraj",
    price: "$30",
    cate: "Adobe XD",
    reviewCount: "03 reviews",
    title: "Certified Graphic Design with Free Project Course",
    totalLeson: "18x Lesson",
    schdule: "Online Class",
    authorImgUrl: "assets/images/course/author/02.jpg",
    authorImgAlt: "course author rajibraj91 rajibraj",
    authorName: "Lora Smith",
    btnText: "Read More",
  },
  {
    imgUrl: "assets/images/course/03.jpg",
    imgAlt: "course rajibraj91 rajibraj",
    price: "$30",
    cate: "Adobe XD",
    reviewCount: "03 reviews",
    title: "Theory Learn New Student And Fundamentals",
    totalLeson: "18x Lesson",
    schdule: "Online Class",
    authorImgUrl: "assets/images/course/author/03.jpg",
    authorImgAlt: "course author rajibraj91 rajibraj",
    authorName: "Robot Smith",
    btnText: "Read More",
  },
  {
    imgUrl: "assets/images/course/04.jpg",
    imgAlt: "course rajibraj91 rajibraj",
    price: "$30",
    cate: "Adobe XD",
    reviewCount: "03 reviews",
    title: "Computer Fundamentals Basic Startup Ultricies Vitae",
    totalLeson: "18x Lesson",
    schdule: "Online Class",
    authorImgUrl: "assets/images/course/author/04.jpg",
    authorImgAlt: "course author rajibraj91 rajibraj",
    authorName: "Zinat Zaara",
    btnText: "Read More",
  },
  {
    imgUrl: "assets/images/course/05.jpg",
    imgAlt: "course rajibraj91 rajibraj",
    price: "$30",
    cate: "Adobe XD",
    reviewCount: "03 reviews",
    title: "Boozy Halloween Drinks for the Grown Eleifend Kuismod",
    totalLeson: "18x Lesson",
    schdule: "Online Class",
    authorImgUrl: "assets/images/course/author/05.jpg",
    authorImgAlt: "course author rajibraj91 rajibraj",
    authorName: "Rajib Raj",
    btnText: "Read More",
  },
  {
    imgUrl: "assets/images/course/06.jpg",
    imgAlt: "course rajibraj91 rajibraj",
    price: "$30",
    cate: "Adobe XD",
    reviewCount: "03 reviews",
    title: "Student Want to Learn About Science And Arts",
    totalLeson: "18x Lesson",
    schdule: "Online Class",
    authorImgUrl: "assets/images/course/author/06.jpg",
    authorImgAlt: "course author rajibraj91 rajibraj",
    authorName: "Angel Mili",
    btnText: "Read More",
  },
];

const coursePlans = [
  {
    title: "Barista Course",
    totalLeson: "2 Courses",
    formLink: "https://forms.gle/cWMycvgAYmSvGcv5A",
    btnText: "Read More",
    coursePlanList: [
      {
        plan: "Basic Course",
        duration: "29 Days",
        imgUrl: "assets/images/course/alright/Coffee Couse.jpg",
      },
      {
        plan: "Abroad Focus Full Barista Course",
        duration: "1 Month with internship ",
        description: "10 days to 3 months volunteer",
        imgUrl: "assets/images/course/alright/Coffee Couse.jpg",
      },
    ],
  },
  {
    title: "Bakery Course",
    totalLeson: "3 Courses",
    formLink: "https://forms.gle/Cjhwh9aujtc9pnKSA",
    btnText: "Read More",
    coursePlanList: [
      {
        plan: "Basic Course",
        duration: "1 Month",
        imgUrl: "assets/images/course/alright/Bakery Course.png",
      },
      {
        plan: "Abroad Focus Full Bakery Course",
        duration: "2 month with internship ",
        description: "",
        imgUrl: "assets/images/course/alright/Bakery Course.png",
      },
      {
        plan: "Advanced Bakery Course",
        duration: "3 month with internship ",
        description: "Internship can last from 1 to 3 Months",
        imgUrl: "assets/images/course/alright/Bakery Course.png",
      },
    ],
  },
  {
    title: "Bartender Course",
    totalLeson: "4 Courses",
    formLink: "https://forms.gle/mDjDMdmre3L4LmrX6",
    btnText: "Read More",
    coursePlanList: [
      {
        plan: "Basic Course",
        duration: "2 Month with internship",
        imgUrl: "./assets/images/course/alright/Bardender Course.jpeg",
      },
      {
        plan: "Advance Course",
        duration: "3 months ",
        description: "internship can last  1 month to 3 months",
        imgUrl: "./assets/images/course/alright/Bardender Course.jpeg",
      },
      {
        plan: "Abroad Focus Full Bartender Course",
        duration: "2 month with internship ",
        description: "Internship can last from 1 to 2 Months",
        imgUrl: "./assets/images/course/alright/Bardender Course.jpeg",
      },
    ],
  },
  {
    title: "Sushi Course",
    totalLeson: "2 Courses",
    formLink: "https://forms.gle/ZQhpFkJbZgPC6amp7 ",
    btnText: "Read More",
    coursePlanList: [
      {
        plan: "Basic Course",
        duration: "1 Month",
        imgUrl: "/assets/images/course/alright/Sushi Couse.png",
      },

      {
        plan: "Abroad Focus Full Sushi Course",
        duration: "1 month with internship ",
        imgUrl: "/assets/images/course/alright/Sushi Couse.png",
      },
    ],
  },
];

const upcomingCourses = [
  {
    imgUrl: "assets/images/category/icon/07.jpg",
    imgAlt: "category rajibraj91 rajibraj",
    title: "Cooking/Culinary Arts",
  },
  {
    imgUrl: "assets/images/category/icon/08.jpg",
    imgAlt: "category rajibraj91 rajibraj",
    title: "House Keeping",
  },
  {
    imgUrl: "assets/images/category/icon/09.jpg",
    imgAlt: "category rajibraj91 rajibraj",
    title: "Front Office",
  },
  {
    imgUrl: "assets/images/category/icon/10.jpg",
    imgAlt: "category rajibraj91 rajibraj",
    title: "Waiter",
  },
  {
    imgUrl: "assets/images/category/icon/10.jpg",
    imgAlt: "category rajibraj91 rajibraj",
    title: "Bussiness",
  },
  {
    imgUrl: "assets/images/category/icon/10.jpg",
    imgAlt: "category rajibraj91 rajibraj",
    title: "Home Care/Old age Care",
  },
];

const CoursePage = () => {
  return (
    <Fragment>
      <Header />
      <PageHeader title={"Courses That Match Your Passion"} curPage={""} />

      {/* <GroupSelect /> */}
      <div className="course-section py-4 section-bg">
        <div className="container">
          <div className="section-wrapper">
            <div className="course-showing-part">
              <div className="d-flex flex-wrap align-items-center justify-content-between">
                <div className="course-showing-part-left">
                  {/* <p>Showing 1-6 of 10 results</p> */}
                </div>
                <div className="course-showing-part-right d-flex flex-wrap align-items-center">
                  {/* <span>Sort by :</span> */}

                  {/* <div className="select-item">
                    <SkillSelect select={"all"} />
                    <div className="select-icon">
                      <i className="icofont-rounded-down"></i>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="section-header pt-5">
              <h2
                className="subtitle text-center"
                style={{ fontSize: "2rem", letterSpacing: "0px" }}
              >
                At Alright Academy, we offer career-focused courses that equip
                you with the skills and knowledge you need for success.
              </h2>
            </div>
            {/* <Course /> */}
            {coursePlans.map((plan, i) => (
              <div key={i} className="my-4">
                <h2 className="title">{plan.title}</h2>

                <div className="row g-4 row-cols-xl-3 row-cols-md-2 row-cols-1">
                  {plan.coursePlanList.map((course, index) => (
                    <div className="col" key={index}>
                      <div className="course-item">
                        <div
                          className="course-inner"
                          // style={{ height: "515px" }}
                        >
                          <div className="course-thumb">
                            <img
                              src={`${course.imgUrl}`}
                              alt={`${index}image`}
                              style={{ height: "80%" }}
                            />
                          </div>
                          <div className="course-content">
                            <h5>{course.plan}</h5>

                            {/* <div className="course-price">{val.price}</div> */}
                            <div className="course-category">
                              {/* <div className="course-cate">
                            <a href="#">{val.cate}</a>
                          </div> */}
                              <div className="course-reiew">
                                <Rating />
                                <span className="ratting-count">
                                  {" "}
                                  05 Reviews
                                </span>
                              </div>
                            </div>
                            <div>It is a {course.duration} Course</div>
                            <div className="mb-4"> {course.description} </div>
                            {/* <div className="course-details">
                          <div className="couse-count">
                            <i className="icofont-video-alt"></i>{" "}
                            {val.totalLeson}
                          </div>
                          <div className="couse-topic">
                            <i className="icofont-signal"></i> {val.schdule}
                          </div>
                        </div> */}
                            <div className="course-footer d-flex justify-content-end">
                              {/* <div className="course-author">
                            <img
                              src={`${val.authorImgUrl}`}
                              alt={`${val.authorImgAlt}`}
                            />
                            <Link to="/team-single" className="ca-name">
                              {val.authorName}
                            </Link>
                          </div> */}
                              <div className="course-btn">
                                <Link
                                  to={plan?.formLink}
                                  className="lab-btn-text"
                                  target="_blank"
                                >
                                  Lets Enroll
                                  <i className="icofont-external-link"></i>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  {/* ))} */}
                </div>
              </div>
            ))}
            {/* <Pagination /> */}
          </div>
        </div>
      </div>

      {/* <div className="my-4 p-4">
        <h2 className="text-center">Future Upcoming Courses</h2>{" "}
        <div className="row g-4 row-cols-xl-3 row-cols-md-2 row-cols-1 m-4">
          {upcomingCourses.map((val, i) => (
            <div className="col" key={i}>
              <div className="category-item text-center">
                <div className="category-inner">
                  <div className="category-thumb">
                    <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                  </div>
                  <div className="category-content">
                    <Link to="/course">
                      <h6>{val.title}</h6>
                    </Link>
                    <span>{val.desc}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div> */}
      <CategoryTwo />
      <Footer />
    </Fragment>
  );
};

export default CoursePage;
