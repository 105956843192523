import { Component, Fragment } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header";
import PageHeader from "../component/layout/pageheader";
import GoogleMap from "../component/sidebar/googlemap";

const subTitle = "Get in touch with us";
const title = "We're Always Eager To Hear From You!";
const conSubTitle = "Get in touch with Contact us";
const conTitle =
  "Fill The Form Below So We Can Get To Know You And Your Needs Better.";
const btnText = "Send our Message";

const contactList = [
  {
    imgUrl: "assets/images/icon/location.png",
    imgAlt: "contact icon",
    title: "Office Address",
    desc: "Newroad,Pokhara",
    link: "https://maps.app.goo.gl/GGgcywaYoKMX1Zkr7",
  },
  {
    imgUrl: "assets/images/icon/call.png",
    imgAlt: "contact icon",
    title: "Phone number",
    desc: "+977 9802802464",
    link: "tel:+9779802802464",
  },
  {
    imgUrl: "assets/images/icon/email.png",
    imgAlt: "mail icon",
    title: "Send email",
    desc: "info@alrightacademy.com",
    link: "mailto:info@alrightacademy.com",
  },
  {
    imgUrl: "assets/images/icon/web.png",
    imgAlt: "contact icon",
    title: "Our website",
    desc: "https://alrightacademy.com/",
    link: "https://alrightacademy.com/",
  },
];

const ContactPage = () => {
  return (
    <Fragment>
      <Header />
      <PageHeader title={"Get In Touch With Us"} curPage={"Contact Us"} />
      <div className="map-address-section padding-tb section-bg">
        <div className="container">
          <div className="section-header text-center">
            <span className="subtitle">{subTitle}</span>
            <h2 className="title">{title}</h2>
          </div>
          <div className="section-wrapper">
            <div className="row flex-row-reverse">
              <div className="col-xl-4 col-lg-5 col-12">
                <div className="contact-wrapper">
                  {contactList.map((val, i) => (
                    <div className="contact-item" key={i}>
                      <div className="contact-thumb">
                        <a href={val.link} target="_blank">
                          <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                        </a>
                      </div>
                      <div className="contact-content">
                        <a href={val.link} target="_blank">
                          <h6 className="title">{val.title}</h6>
                        </a>
                        <p>{val.desc}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-xl-8 col-lg-7 col-12">
                <GoogleMap />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="contact-section padding-tb">
                <div className="container">
                    <div className="section-header text-center">
                        <span className="subtitle">{conSubTitle}</span>
                        <h2 className="title">{conTitle}</h2>
                    </div>
                    <div className="section-wrapper">
                        <form className="contact-form">
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Your Name *"
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="email"
                                    placeholder="Your Email *"
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="number"
                                    placeholder="Mobile Number *"
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="subject"
                                    placeholder="Your Subject *"
                                />
                            </div>
                            <div className="form-group w-100">
                                <textarea 
                                    rows="8" 
                                    type="text"
                                    placeholder="Your Message"
                                ></textarea>
                            </div>
                            <div className="form-group w-100 text-center">
                                <button className="lab-btn"><span>{btnText}</span></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div> */}
      <Footer />
    </Fragment>
  );
};

export default ContactPage;
