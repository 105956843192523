const title = "Build and Shape Your Career Goals with Alright Academy";
const btnText = "Sign Up Now";

const skillList = [
  {
    imgUrl: "assets/images/skill/icon/instructor.png",
    imgAlt: "Instructor icon",
    title: "Skilled Instructors",
    desc: "Learn From the best.",
  },
  {
    imgUrl: "assets/images/skill/icon/courses.png",
    imgAlt: "courses icon",
    title: "Variety of Courses",
    desc: "Choose your desired field.",
  },
  {
    imgUrl: "assets/images/skill/icon/certificate.png",
    imgAlt: "ceritificate icon",
    title: "Get a Certificate",
    desc: "Recognition and talent.",
  },
  {
    imgUrl: "assets/images/skill/icon/intern.png",
    imgAlt: "internship icon",
    title: "Get Internship",
    desc: "Opportunity for growth.",
  },
];

const Skill = () => {
  return (
    <div className="skill-section padding-tb">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-5 col-12">
            <div className="section-header">
              <h2 className="title">{title}</h2>
              {/* <Link to="/signup" className="lab-btn">
                <span>{btnText}</span>
              </Link> */}
            </div>
          </div>
          <div className="col-lg-7 col-12">
            <div className="section-wrpper">
              <div className="row g-4 justify-content-center row-cols-sm-2 row-cols-1">
                {skillList.map((val, i) => (
                  <div className="col" key={i}>
                    <div className="skill-item">
                      <div className="skill-inner">
                        <div className="skill-thumb">
                          <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                        </div>
                        <div className="skill-content">
                          <h5>{val.title}</h5>
                          <p>{val.desc}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skill;
