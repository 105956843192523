const aboutList = [
  {
    imgUrl: "assets/images/about/icon/01.png",
    imgAlt: "RegisterImg",
    title: "Register Online",
    desc: "Fill out the form & register online.",
  },
  {
    imgUrl: "assets/images/about/icon/02.jpg",
    imgAlt: "teacher Img",
    title: "Experts Teachers",
    desc: "Get the chance to learn from the best.",
  },
  {
    imgUrl: "assets/images/about/icon/03.jpg",
    imgAlt: "Growth Img",
    title: "Opportunity for Growth",
    desc: "After course completion get internship opportunities.",
  },
];

const AboutTwo = () => {
  return (
    <div className="about-section style-2 section-bg">
      <div className="container">
        <div className="row justify-content-center row-cols-lg-3 row-cols-sm-2 row-cols-1">
          {aboutList.map((val, i) => (
            <div className="col" key={i}>
              <div className="about-right">
                <div className="section-wrapper">
                  <ul className="lab-ul">
                    <li>
                      <div className="sr-left">
                        <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                      </div>
                      <div className="sr-right">
                        <h5>{val.title}</h5>
                        <p>{val.desc}</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutTwo;
